<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="80px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="dataForm.name"
          :disabled="disabled"
          placeholder="名称"
        />
      </el-form-item>
      <el-form-item label="跳转链接" prop="url">
        <el-input
          v-model="dataForm.url"
          :disabled="disabled"
          placeholder="跳转链接"
        />
      </el-form-item>
      <el-form-item label="ICON链接" prop="iconUrl">
        <el-img v-model="dataForm.iconUrl" :disabled="disabled" />
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="dataForm.status" :disabled="disabled">
          <el-radio :label="0"> 隐藏 </el-radio>
          <el-radio :label="1"> 显示 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input
          v-model="dataForm.sort"
          :disabled="disabled"
          placeholder="排序"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: '',
        name: '',
        categoryId: '',
        iconUrl: '',
        sort: '',
        status: 1,
      },
      dataRule: {
        categoryId: [
          {
            required: true,
            message: '商品分类不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/mall/channel/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = data.channel;
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/mall/channel/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
